import useForm from "./services/useForm";
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';
import DiscordService from "./services/DiscordService";
import './css/partner.css'

export const initialFormState = {
  data: {
    Usuario: "",
    Miembros: "",
    Nombre: "",
    Invitación: "",
    Beneficios: "",
  },
  error: {},
};


function PartnerApp() {

 
  const { formData, setDynamicFormData, clearForm, isValid } =
    useForm(initialFormState);
  const btn_color = isValid ? "#006dff" : ""
  const btn_opacity = isValid ? "100%" : "30%"

  const { Send } = DiscordService(clearForm);

  const PostToDiscord = () => {
    const description = Object.entries(formData.data)
      .map((d) => `**${d[0]}** : ${d[1]}`)
      .join("\n");

    Send(description);
  };

  return (
    
    
    <>
      <Barra />
      <main className="title-main">
        <div className="title">
          <h1>PARTNERS</h1>
          <h3>¡ Hazte Partener de WelNow !</h3>
        </div>
      </main>

      <div className="Partner">
      <form
            onSubmit={(e) => {
              window.location.href='/'
              e.preventDefault();
              PostToDiscord();
            }}
          >

        <div className="vh-100 d-flex justify-content-center align-items-center">
          <div className="card">


              <div >
                <h3 className="cnt">Usuario de discord <span style={{ opacity: '30%', fontSize: "15px", marginLeft: "5px" }}><strong>Ej: </strong>Luc4s#0857</span></h3>
                <textarea cols="60" rows="1" name="Usuario" value={formData.data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                ></textarea>
                {formData.error.message ? (<div>{formData.error.message}</div>) : ("")}
              </div>

              <div>
                <h3 className="cnt">Nombre del servidor  <span style={{ opacity: '30%', fontSize: "15px", marginLeft: "5px" }}><strong>Ej: </strong>WelNow Support</span></h3>
                <textarea cols="60" rows="1" name="Nombre" value={formData.data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                ></textarea>
                {formData.error.message ? (<div>{formData.error.message}</div>) : ("")}
              </div>

              <div>
                <h3 className="cnt">Miembros del servidor  <span style={{ opacity: '30%', fontSize: "15px", marginLeft: "5px" }}><strong>Ej: </strong>1000 miembros</span></h3>
                <textarea cols="60" rows="1" name="Miembros" value={formData.data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                ></textarea>
                {formData.error.message ? (<div>{formData.error.message}</div>) : ("")}
              </div>

              <div>
                <h3 className="cnt">Invitación del servidor  <span style={{ opacity: '30%', fontSize: "15px", marginLeft: "5px" }}><strong>Ej: </strong>discord.gg/abcdefgh</span></h3>
                <textarea cols="60" rows="1" name="Invitación" value={formData.data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                ></textarea>
                {formData.error.message ? (<div>{formData.error.message}</div>) : ("")}
              </div>

              
              <div>
                <h3 className="cnt">¿Cómo vas ha hacerle públicidadd al bot?  </h3>
                <h4 style={{ opacity: '30%', fontSize: "15px", marginLeft: "5px" }}>A cambio obtendrás de los beneficios de partner</h4>
                <textarea cols="60" rows="3" name="Beneficios" value={formData.data.message}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDynamicFormData(name, value);
                  }}
                ></textarea>
                {formData.error.message ? (<div>{formData.error.message}</div>) : ("")}
              </div>




              <button
                type="submit"
                style={{ padding: "10px 20px", margin: "20px" , backgroundColor: btn_color, opacity: btn_opacity}}
                disabled={!isValid}
              >
                {isValid?"Enviar Solicitud":"Completa todas las preguntas"}
              </button>
            
          </div>
        </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default PartnerApp;