import './css/terms.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function PrivacyApp() {
    return (

        <>

            <Barra />


            <main class="terms">
    <div class="title">
      <h1>Política de privacidad</h1>
      <h3>Política de privacidad WelNow</h3>

      <div class="terms-p">
        <p class="parrafo">
          <span>Recopilación de datos</span> <br />
          Welnow puede recopilar ciertos datos personales de los usuarios de Discord, incluyendo el nombre de usuario,
          la
          identificación de usuario única (ID), la fecha de registro de la cuenta, la información de la cuenta de
          Discord, los
          mensajes enviados a través del bot. Estos datos pueden ser recopilados automáticamente a través de Discord API
          o
          a través de interacciones específicas del usuario con el bot.
        </p>
      </div>

      <div class="terms-p">
        <p class="parrafo">
          <span>Uso de datos</span> <br />
          Los datos personales recopilados por el bot se utilizan para proporcionar y mejorar los servicios del bot y
          personalizar la experiencia del usuario. Welnow puede utilizar estos datos para responder a las solicitudes de
          los usuarios, proporcionar información y recursos relevantes, y mejorar la calidad del servicio ofrecido.
        </p>
      </div>

      <div class="terms-p">
        <p class="parrafo">
          <span>Divulgación de datos</span> <br />
          Welnow no divulgará ningún dato personal de los usuarios a terceros, excepto en los casos en que sea necesario
          para cumplir con la ley o proteger los derechos, la propiedad o la seguridad del bot o de los usuarios de
          Discord.
        </p>
      </div>

      <div class="terms-p">
        <p class="parrafo">
          <span>Seguridad de los datos</span> <br />
          Welnow se compromete a proteger los datos personales de los usuarios y tomar medidas razonables para prevenir
          el acceso no autorizado, la divulgación o el uso indebido de los datos personales.
        </p>
      </div>

      <div class="terms-p">
        <p class="parrafo">
          <span>Modificaciones de la política de privacidad</span> <br />
          Welnow se reserva el derecho de modificar esta Política de privacidad en cualquier momento. Se recomienda a
          los usuarios que revisen esta política de vez en cuando para estar al tanto de cualquier cambio.
        </p>
      </div>
    </div>
  </main>


            <Footer />
        </>


    );
}

export default PrivacyApp;
