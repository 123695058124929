import './css/404.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function PageNotFound() {
    return (

        <main>

            <Barra />



            <div className="contents">

                <div className="cnt">
                    <h1 id='h1'>ERROR 404</h1>
                    <h2>Página no encontrada</h2>
                </div>
            </div>


            <Footer />
        </main>


    );
}

export default PageNotFound;
