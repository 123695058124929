import './css/footer.css';

function Footer() {
  return (

  <footer className="footer">
    <div className="footer__addr">
      <img src="../media/footer.png" className="footer__logo" />
      <h2>Contact</h2>
      <address>
        <a className="footer__btn" href="mailto:admin@welnow.es">admin@welnow.es</a>
      </address>
    </div>

    <ul className="footer__nav">

      <li className="nav__item">
        <h2 className="nav__title">Welnow Bot</h2>
        <ul className="nav__ul">
          <li><a href="./">Inicio</a></li>
          <li><a href="./comandos">Comandos</a></li>
          <li><a href="./equipo">Equipo</a></li>
          <li><a href="./premium">Premium</a></li>
        </ul>
      </li>

      <li className="nav__item">
        <h2 className="nav__title">Enlaces</h2>
        <ul className="nav__ul">
          <li><a href="https://discord.gg/nnzWuZxHef">Discord Server</a></li>
          <li><a
              href="https://discord.com/api/oauth2/authorize?client_id=1083045954271314052&permissions=140392130672&scope=bot">Invitación
              Bot</a></li>
          <li><a href="../partners">Partners</a></li>
          <li><a href="../guide">Guía</a></li>
        </ul>
      </li>


      <li className="nav__item">
        <h2 className="nav__title">Legal</h2>
        <ul className="nav__ul">
          <li><a href="../privacy">Política de privacidad</a></li>
          <li><a href="../tos">Términos y condiciones de servicio</a></li>
        </ul>
      </li>
    </ul>

    <div className="legal">
      <p>&copy; 2023 Todos los derechos reservados a <strong>WelNow Team</strong></p>
      <div className="legal__links">
        <span>Desarrollado con <strong>Reactjs</strong> por <strong>WelNow Team</strong></span>
      </div>
    </div>
  </footer>

  );
}

export default Footer;
