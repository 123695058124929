import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import ComandosApp from './paginas/comandos';
import EquipoApp from './paginas/equipo';
import PremiumApp from './paginas/premium';
import PartnerApp from './paginas/partner';
import TosApp from './paginas/tos';
import PrivacyApp from './paginas/privacy';
import GuiaApp from './paginas/guide';
import PageNotFound from './paginas/404';

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/comandos", element: <ComandosApp /> },
  { path: "/equipo", element: <EquipoApp /> },
  { path: "/premium", element: <PremiumApp /> },
  { path: "/partners", element: <PartnerApp /> },
  { path: "/tos", element: <TosApp /> },
  { path: "/privacy", element: <PrivacyApp /> },
  { path: "/guide", element: <GuiaApp /> },
  { path: "", element: <PageNotFound /> },
  { path: "*", element: <PageNotFound /> },
  { element: <PageNotFound /> },

])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
