import './css/comandos.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function ComandosApp() {
  return (

    <main>

      <Barra />


      <main className="title-main">
        <div className="title">
          <h1>Comandos</h1>
          <h3>¡ Comandos de WelNow !</h3>
        </div>
      </main>

      <main className="cmd">

        <div className="comando">
          <h2 href="">/config</h2>
          <a>Comando para iniciar la configuración del bot</a>
        </div>

      </main>


      <Footer />
    </main>


  );
}

export default ComandosApp;
