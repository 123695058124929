import './css/guide.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function GuiaApp() {
    return (

        <>

            <Barra />
            <main class="info">
    <div class="title">
      <h1>Guía WelNow</h1>
      <h3>Guía de cómo configurar a WelNow</h3>
      <iframe class="video" width="560" height="315" src="https://www.youtube.com/embed/zkKQXnVJE7I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>

    
  </main>

            <Footer />
        </>


    );
}

export default GuiaApp;
