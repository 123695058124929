import './css/barra.css';

function Barra() {
  return (
    <header>
    <div className="text-and-logo">
      <img src="../media/logo.png" alt="logo" />
      <a href="/" className="text-logo">WelNow</a>
    </div>
    <nav>
      <input type="checkbox" id="check"/>
      <label htmlFor="check" className="checkbtn">
        <i className="fa-solid fa-bars"></i>
      </label>
      <ul className="active">
        <li><a href="/comandos" className="li">Comandos</a></li>
        <li><a href="/premium" className="li">Premium</a></li>
        <li><a href="/guide" className="li">Guía</a></li>
       <li id="login"><a href="https://my.welnow.es"><i className="fa-solid fa-user"></i></a></li>
      </ul>
    </nav>
  </header>
  
  );
}

export default Barra;
