import './css/premium.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function PremiumApp() {
    return (

        <>

            <Barra />
            <main className="title-main">
                <div className="title">
                    <h1>PREMIUM</h1>
                    <h3>¡ Hazte Premium de WelNow !</h3>
                </div>
            </main>

            <main className="premium">



                <div className="content-premium">
                    <div className="cartel">
                        <img src="../media/premium/bronce.png" />
                        <hr />
                        <div className="texto">
                            <div className="top">
                                <h1>WelNow Bronce</h1>
                                <h3>2$/mes</h3>
                            </div>

                            <a href="https://www.patreon.com/join/WelNow/checkout?rid=9635632" target="_blank" className="btn1">Obten Premium</a>
                        </div>
                    </div>

                    <div className="cartel">
                        <img src="../media/premium/plata.png" />
                        <hr />
                        <div className="texto">
                            <div className="top">
                                <h1>WelNow Plata</h1>
                                <h3>5$/mes</h3>
                            </div>

                            <a href="https://www.patreon.com/join/WelNow/checkout?rid=9635637" target="_blank" className="btn2">Obten Premium</a>
                        </div>
                    </div>

                    <div className="cartel">
                        <img src="../media/premium/oro.png" />
                        <hr />
                        <div className="texto">
                            <div className="top">
                                <h1>WelNow Oro</h1>
                                <h3>10$/mes</h3>
                            </div>

                            <a href="https://www.patreon.com/join/WelNow/checkout?rid=9635641" target="_blank" className="btn3">Obten Premium</a>
                        </div>
                    </div>
                    
                </div>


            </main>


            <Footer />
        </>


    );
}

export default PremiumApp;
