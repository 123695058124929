import './App.css';
import Barra from './componentes/barra'
import Footer from './componentes/footer'

function App() {
  return (
    <div className="App">
      
      
<Barra/>
  
  <div className="inicio">
  
    
   <div className="texto">
     <h1>WelNow</h1>
     <p>Soy WelNow un bot de bienvenidas y despedidas</p>
  
     <a href="https://discord.gg/V7vumdZKT9" className="btn">Discord</a>
     <a href="https://discord.com/api/oauth2/authorize?client_id=1083045954271314052&permissions=140392130672&scope=bot"
       className="btn">Invitame</a>
   </div>
  
   <img src="./media/logo.png" />
  
  </div>
  
  <Footer />
    
    </div>
  );
}

export default App;
