import './css/equipo.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function EquipoApp() {
    return (

        <main>

            <Barra />

            <main className="title-main">
                <div className="title">
                    <h1>EQUIPO</h1>
                    <h3>¡ Equipo de WelNow !</h3>
                </div>
            </main>

            <main className="team">



                <div className="caja">
                    <img src="../media/users/luc4s.png" alt='' />
                    <h3>Luc4s</h3>
                    <p style={{ opacity:"60%"}}>Bot <span style={{ fontSize:"15px"}}>&</span> Web Developer</p>
                    <a href="https://discord.com/users/897579288444928052"><i id="icono" className="fa-brands fa-discord"></i></a>
                    <a href="https://luc4s.dev"><i id="icono" className="fa-solid fa-arrow-up-right-from-square"></i></a>
                </div>

                <div className="caja">
                    <img src="../media/users/gonzalo.webp" alt='' />
                    <h3>Gonzalo</h3>
                    <p style={{ opacity:"60%"}}>Dash Developer</p>
                    <a href="https://discord.com/users/1003896461420937346"><i id="icono" className="fa-brands fa-discord"></i></a>
                </div>

                <div className="caja">
                    <img src="../media/users/daniel.png" alt='' />
                    <h3>Daniel_Hz</h3>
                    <p style={{ opacity:"60%"}}>Bot Developer</p>
                    <a href="https://discord.com/users/528308070871334912"><i id="icono" className="fa-brands fa-discord"></i></a>
                </div>




            </main>


            <Footer />
        </main>


    );
}

export default EquipoApp;
