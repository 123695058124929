import axios from "axios";
import '../css/partner.css'

function DiscordService(clearForm) {
  const Send = async (data) => {
    const body = {
      content: "¡Aviso **Partners**!",
      tts: false,
      color: "white",
      embeds: [
        {
          title: "Solicitud de **Partner**",
          description: data,
        },
      ],    };

    try {
      const data = await axios.post(
       "https://discord.com/api/webhooks/1089277286970294352/bsCXkUKhDo3s4FbQTiIQ8gSzKpKENU8ZWWqoYp4psrMMGAqGTbr3e49h9hm5IkXrSMDW",body
      );
      console.log(data);
      clearForm();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    Send,
  };
}

export default DiscordService;