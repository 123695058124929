import './css/terms.css';
import Barra from '../componentes/barra';
import Footer from '../componentes/footer';

function TosApp() {
    return (

        <>

            <Barra />


            
            <main className="terms">
    <div className="title">
      <h1>Términos y condiciones de servicio</h1>
      <h3>Términos y condiciones de servicio WelNow</h3>

      <div className="terms-p">
        <p className="parrafo">
          <span>Servicios ofrecidos</span><br />
          WelNow proporciona una variedad de servicios a los usuarios, incluyendo la realización de acciones
          específicas en los servidores de Discord donde está instalado, la realización de búsquedas y la entrega de
          información relevante. El bot se compromete a proporcionar estos servicios de manera eficiente y profesional.
        </p>
      </div>

      <div className="terms-p">
        <p className="parrafo">
          <span>Uso del bot</span><br />
          WelNow - Team se reserva el derecho de limitar o prohibir el uso del bot a cualquier usuario que viole estos
          términos
          y condiciones o que utilice el bot de manera indebida o para fines ilegales. El uso del bot para actividades
          ilegales o maliciosas está estrictamente prohibido.
        </p>
      </div>

      <div className="terms-p">
        <p className="parrafo">
          <span>Propiedad intelectual</span><br />
          Todo el contenido y la propiedad intelectual de WelNow, incluyendo su código, diseños, marcas registradas,
          logotipos y otros materiales, son propiedad exclusiva WelNow. El uso no autorizado de este contenido está
          estrictamente prohibido.
        </p>
      </div>

      <div className="terms-p">
        <p className="parrafo">
          <span>Responsabilidad</span><br />
          WelNow - Team no se hace responsable de cualquier daño directo, indirecto, incidental, especial, consecuente o
          punitivo que surja del uso o la imposibilidad de usar el bot o de cualquier otro modo relacionado con el bot.
        </p>
      </div>

      <div className="terms-p">
        <p className="parrafo">
          <span>Modificaciones del servicio</span><br />
          WelNow - Team se reserva el derecho de modificar, suspender o descontinuar cualquier parte del servicio en
          cualquier
          momento y sin previo aviso.
        </p>
      </div>

      <div className="terms-p">
        <p className="parrafo">
          <span>Modificaciones del servicio</span><br />
          El bot se reserva el derecho de modificar, suspender o descontinuar cualquier parte del servicio en cualquier
          momento y sin previo aviso.
        </p>
      </div>

      <div className="terms-p">
        <p className="parrafo">
          <span>Contacto</span><br />
          Si tiene alguna pregunta o inquietud sobre estos términos y condiciones, por favor póngase en contacto con el
          equipo de soporte del WelNow a través de Discord.
        </p>
      </div>
    </div>
  </main>

            <Footer />
        </>


    );
}

export default TosApp;
